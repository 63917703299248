<template>
    <el-row>
        <el-col :span="6">
            <Aside class="aside__layout" />
        </el-col>
        <el-col :span="18">
            <div class="main__layout" ref="main">
            <router-view />
        </div>
        </el-col>
    </el-row>
</template>
<script>
import Aside from "@/components/layout/Aside.vue"
export default {
    name:"",
    components:{
        Aside,
    },
}
</script>
<style scoped>
.aside__layout{
    min-width: 200px;
    flex-shrink: 0;
    flex-grow: 0;
}
.main__layout{
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100vh;
}
</style>
