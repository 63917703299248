<template>
  <div style="position: relative">
    <div class="aside__container">
      <div class="aside-header">
        <i class="iconfont icon-shiwu-gouwuche" @click="openCarHandle"></i>
      </div>
      <div class="aside-logo">
        <a href="/">
          <img src="@/assets/img/icon/logo.png" />
        </a>
      </div>
      <div class="main-links-wrap flex-column font-medium">
        <a
          :href="item.link"
          v-for="(item, index) in moreMenuMain"
          :key="index"
          :class="$route.path.indexOf(item.link) != -1 ? 'active' : ''"
        >
          <span>{{ $trans(item.title, item.title) }}</span>
        </a>
      </div>
      <div class="about-links-wrap flex-column font-medium">
        <a
          :href="item.link"
          v-for="(item, index) in moreMenuAbout"
          :key="index"
          :class="$route.path === item.link ? 'active' : ''"
        >
          <span>{{ $trans(item.title, item.title) }}</span>
        </a>
      </div>

      <div class="about-links-wrap flex">
        <el-dropdown trigger="click" @command="changeLanguage">
          <span class="el-dropdown-link">
            {{ currentLanguage
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
            <el-dropdown-item command="EN">English</el-dropdown-item>
            <el-dropdown-item command="DE">Deutsch</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="aside-search-container">
        <div class="aside-search-box flex-bet-cen">
          <input
            type="text"
            @keyup.enter="searchHandle"
            v-model="searchObj.text"
            :placeholder="$trans('请输入要查询的内容', '请输入要查询的内容')"
          />
          <i class="iconfont icon-sousuo" @click="searchHandle"></i>
        </div>
      </div>
      <!-- 搜索框 -->
      <el-drawer
        :visible.sync="searchObj.show"
        direction="ltr"
        size="35%"
        :show-close="false"
        class="search__box"
      >
        <div class="search-result-box">
          <div class="artist-content-result">
            <div v-if="searchObj.artistList && searchObj.artistList.length > 0">
              <ArtistResult
                v-for="(item, index) in searchObj.artistList"
                :key="index"
                :data="item"
              />
            </div>
            <div v-if="searchObj.shopList && searchObj.shopList">
              <GoodsResult
                v-for="(item, index) in searchObj.shopList"
                :key="index"
                :data="item"
              />
            </div>
          </div>
        </div>
      </el-drawer>
      <!-- 购物车 -->
      <el-drawer
        :visible.sync="goodsObj.show"
        direction="ltr"
        :show-close="true"
        size="35%"
        class="shops-car-wrap"
      >
        <div class="shops-car-box">
          <div
            class="has-car"
            v-if="$store.state.isLogin && goodsObj.goods.length > 0"
          >
            <div class="line-bottom">
              {{ $trans('您的购物车内有', '您的购物车内有')
              }}{{ goodsObj.goods.length }}{{ $trans('件商品', '件商品') }}
            </div>
            <div class="shop-car-box">
              <ShopCarItem
                @on="getGoodsList"
                :data="item"
                v-for="item in goodsObj.goods"
                :key="item.skuId"
              />
            </div>
            <SumMoney
              @on="goodsObj.show = false"
              class="summoney-box"
              :data="goodsObj.goods"
            />
          </div>
          <div class="empty-car" v-else>
            <div class="line-bottom">
              {{ $trans('您的购物车还是空的', '您的购物车还是空的') }}
            </div>
            <div class="btn-fill" style="margin-top: 30px; letter-spacing: 4px">
              {{ $trans('继续浏览', '继续浏览') }}
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import {
  setLanguage,
  getLanguage,
  getLanguageShow,
} from '@/util/js/languageConfig'
import { loadLocaleMessages } from '@/i18n/i18n'
import { moreMenuMain, moreMenuAbout } from '@/util/resource/static.jsx'
import ShopCarItem from '@/components/shop/ShopCarItem.vue'
import SumMoney from '@/components/shop/Summoney.vue'
import GoodsResult from '@/components/search/GoodsResult.vue'
import ArtistResult from '@/components/search/ArtistResult.vue'
import { getGoodsList, searchArtist } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      goodsObj: {
        show: false,
        goods: [],
      },
      searchObj: {
        show: false,
        text: '',
        artistList: [],
        shopList: [],
      },
      currentLanguage: getLanguageShow() || '中文',
      locale: getLanguage() || 'zh-CN',
    }
  },

  computed: {
    moreMenuMain() {
      return moreMenuMain()
    },
    moreMenuAbout() {
      return moreMenuAbout()
    },
  },
  methods: {
    // 打开购物
    openCarHandle() {
      this.goodsObj.show = true
      this.getGoodsList()
    },
    // 搜索操作
    searchHandle() {
      searchArtist({
        keywords: this.searchObj.text,
        currentLang: this.$i18n.locale,
      })
        .then((res) => {
          if (res.code == '200') {
            if (
              JSON.stringify(res.data) == '{}' ||
              (res.data.artistList.length == 0 && res.data.spuList.length == 0)
            ) {
              this.searchObj.show = false
              this.$message.warning(
                this.$trans('没有搜索到匹配的数据', '没有搜索到匹配的数据')
              )
            } else {
              this.searchObj.artistList = res.data.artistList
                ? JSON.parse(JSON.stringify(res.data.artistList))
                : []
              this.searchObj.artistList.forEach((item) => {
                if (item.transferList) {
                  loadLocaleMessages(item.transferList, `artist-${item.id}`)
                }
              })
              this.searchObj.shopList = res.data.spuList
                ? JSON.parse(JSON.stringify(res.data.spuList))
                : []
              this.searchObj.show = true
              this.searchObj.shopList.forEach((item) => {
                if (item.transferList) {
                  loadLocaleMessages(item.transferList, `goods-${item.id}`)
                }
                if (item.artistTransferList) {
                  loadLocaleMessages(
                    item.artistTransferList,
                    `artist-${item.artistId}`
                  )
                }
              })
            }
          } else {
            this.searchObj.show = false
            this.$message.warning(
              this.$trans('没有搜索到匹配的数据', '没有搜索到匹配的数据')
            )
          }
        })
        .catch(() => {
          this.$message.error(this.$trans('网络错误', '网络错误'))
        })
    },
    // 获取购物车数据
    getGoodsList() {
      getGoodsList({
        userId: JSON.parse(localStorage.getItem('user')).id,
      }).then((res) => {
        if (res.data) {
          this.goodsObj.goods = JSON.parse(JSON.stringify(res.data))
          res.data.forEach((item) => {
            // console.log('item:', item)
            if (item.transferList) {
              loadLocaleMessages(item.transferList, `goods-${item.spuId}`)
            }
            if (item.artistTransferList) {
              loadLocaleMessages(
                item.artistTransferList,
                `artist-${item.artistId}`
              )
            }
          })
        } else {
          this.goodsObj.goods = []
        }
      })
    },
    changeLanguage(lang) {
      // console.log('changeLanguage:', lang)
      this.$i18n.locale = lang
      setLanguage(lang)
      this.initLang()
    },
    initLang() {
      this.currentLanguage = getLanguageShow() || '中文'
      this.locale = getLanguage() || 'zh-CN'
    },
  },
  components: {
    ShopCarItem,
    SumMoney,
    GoodsResult,
    ArtistResult,
  },
}
</script>
<style scoped>
.aside__container {
  padding-right: 10%;
  padding-left: 15%;
  border-right: 1px solid rgba(17, 17, 17, 0.1);
  height: 100vh;
  position: relative;
  flex: 0;
}
.aside-header {
  padding-top: 40px;
  text-align: right;
}
.aside-header i {
  font-size: 28px;
  line-height: 60px;
  cursor: pointer;
}
.aside-logo {
  width: 46%;
  margin-bottom: 100px;
}
.main-links-wrap {
  margin-top: 30px;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 40px;
  font-weight: bold;
}
.about-links-wrap {
  margin-top: 70px;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 34px;
  font-weight: bold;
}
.aside-search-container {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 20%);
}
.aside-search-box {
  border-bottom: 1px solid rgba(144, 144, 144, 1);
  padding-bottom: 5px;
}
.aside-search-box span {
  flex: 1;
  font-size: 14px;
  width: 140px;
  line-height: 2;
  color: #999999;
}
.header__container-search {
  padding-top: 40px;
}
.aside-search-box input {
  outline: none;
  border: none;
  line-height: 40px;
  flex-grow: 1;
}
.header__container-search .delete {
  cursor: pointer;
}
.shops-car-box {
  font-size: 14px;
  color: #999999;
  line-height: 30px;
}
.has-car {
  color: #111111;
  font-size: 14px;
  line-height: 30px;
}
.shop-car-box {
  height: calc(100vh - 51px - 31px - 160px - 20px);
  overflow-y: scroll;
}
.icon-sousuo {
  font-size: 24px;
  cursor: pointer;
}
.summoney-box {
  padding-top: 10px;
}
</style>
<style>
.aside__container .el-drawer__wrapper,
.aside__container .v-modal {
  left: 25%;
}
.el-drawer {
  padding: 0 30px;
}
.shops-car-wrap .el-drawer__header {
  padding: 30px 0 0;
  margin-bottom: 0;
}
.search__box .el-drawer__header {
  display: none;
}
</style>
