<template>
  <div class="artist__second-component line-bottom">
    <div class="artist__second-header flex">
      <div class="artist__second-img">
        <img :src="data.profileImg1.split(',')[0]" alt="" />
      </div>
      <div class="artist__second-content flex-column-start">
        <div class="artist__second-title font-medium ellips-3">
          {{ $trans(`artist-${data.id}.detailTitle`, data.detailTitle) }}
        </div>
        <div class="artist__second-author font-medium">
          「{{ $trans(`artist-${data.id}.name`, data.name) }}」
        </div>

        <div class="artist__second-article">
          {{ $trans(`artist-${data.id}.brief`, data.brief) }}
        </div>
        <!-- <div class="artist__second-title font-medium">{{title}}</div> -->
        <!-- <div class="artist__second-author font-medium">「{{ data.name }}」</div> -->
        <!-- <div class="artist__second-article">{{ data.brief }}</div> -->
      </div>
    </div>
    <div class="artist-more-result flex">
      <a :href="'/artist/detail/' + data.id">{{
        $trans('继续阅读', '继续阅读')
      }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    data: {
      typeof: Object,
    },
  },
  computed: {
    title() {
      return this.data.content.split('<sign>')[0]
    },
  },
}
</script>
<style scoped>
.artist__second-component {
  width: 100%;
  padding: 30px 0;
}
.artist__second-img {
  width: 170px;
  height: auto;
  display: block;
  flex-shrink: 0;
}
.artist__second-content {
  margin-left: 20px;
}
.artist__second-title {
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
}
.artist__second-author {
  font-size: 12px;
  margin-left: -5px;
}
.artist__second-article {
  margin-top: 5px;
  line-height: 20px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.artist-more-result {
  width: 100%;
  justify-content: flex-end;
  margin-top: 15px;
}
.artist-more-result a {
  color: #000000;
  font-size: 12px;
  line-height: 20px;
  border-bottom: 1px solid #000000;
}
</style>
