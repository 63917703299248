<template>
  <div class="goods__second-result line-bottom flex-bet">
    <div class="flex goods__second-left">
      <SmallImg :image="data.image" />
      <div class="goods-info-result flex-column-stair">
        <div class="goods-title-result font-medium">
          {{ $trans(`goods-${data.id}.title`, data.title) }}
        </div>
        <div class="goods-author-result">
          「{{ $trans(`artist-${data.artistId}.name`, data.artist.name) }}」
        </div>
      </div>
    </div>
    <div class="goods-more-result">
      <a :href="'/shop/detail/' + data.id">查看详情</a>
    </div>
  </div>
</template>
<script>
import SmallImg from '@/components/img/Small.vue'
export default {
  name: '',
  props: {
    data: {
      typeof: Object,
    },
  },
  components: {
    SmallImg,
  },
}
</script>
<style scoped>
.goods__second-result {
  width: 100%;
  padding: 30px 0;
  align-items: flex-end;
}
.goods__second-left {
  align-items: flex-start;
}
.goods-info-result {
  margin-left: 16px;
}
.goods-title-result {
  font-size: 18px;
  line-height: 30px;
}
.goods-author-result {
  color: #595959;
  transform: translateX(-8px);
}
.goods-more-result {
  color: #000000;
  font-size: 12px;
  line-height: 20px;
  border-bottom: 1px solid #000000;
}
</style>
