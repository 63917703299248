<template>
  <div class="shop-second__component flex-bet-cen line-bottom">
    <div class="item-left flex">
      <SmallImg
        :layer="data.goodsStock == 2"
        :maxWidth="30"
        :maxHeight="40"
        :boxWidth="50"
        :boxHeight="60"
        :image="data.image"
      />
      <div class="item__info flex-column-start">
        <div class="object__message">
          <div class="object__name font-medium">
            {{ $trans(`goods-${data.spuId}.title`, data.spuTitle) }}
          </div>
          <div class="object__author">
            「{{ $trans(`artist-${data.artistId}.name`, data.artistName) }}」
          </div>
        </div>
        <div class="specification">
          {{ data.specTitle }}
        </div>
        <div class="object__sum flex-cen">
          <div class="object__price">
            <div class="object__int normal">￥</div>
            <div class="object__int">
              {{ (data.price * data.count) | product_int }}
            </div>
            <div class="object__float">
              {{ (data.price * data.count) | product_float }}
            </div>
          </div>
          <div class="object__price grey">
            <div class="object__int">({{ data.count }}×</div>
            <div class="object__int normal">￥</div>
            <div class="object__int">{{ data.price | product_int }}</div>
            <div class="object__float">{{ data.price | product_float }}</div>
            <div class="object__int">)</div>
          </div>
        </div>
      </div>
    </div>
    <el-input-number
      v-model="data.count"
      size="mini"
      :min="1"
      :max="99"
      v-if="dialogNumberShow"
    ></el-input-number>
    <div class="item-right flex-cen" v-else>
      <span class="shop-item__input">
        <input type="text" @click="changeNumberHandle" v-model="data.count" />
      </span>
      <el-popover
        placement="right"
        class="delete-shop-popover"
        width="160"
        v-model="tipAffirmVisible"
      >
        <p>{{ $trans('确定要删除该商品吗', '确定要删除该商品吗') }}</p>
        <div style="text-align: right; margin: 0; margin-top: 10px">
          <el-button
            size="mini"
            type="text"
            @click="tipAffirmVisible = false"
            >{{ $trans('取消', '取消') }}</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="ensureHandle(data.skuId)"
            >{{ $trans('确定', '确定') }}</el-button
          >
        </div>
      </el-popover>
      <span class="delete" @click="tipAffirmVisible = true">×</span>
    </div>
  </div>
</template>
<script>
import SmallImg from '@/components/img/Small.vue'
import { deleteShopCar, addShopCar } from '@/util/js/api.js'
export default {
  name: '',
  props: {
    data: {
      typeof: Object,
    },
  },
  data() {
    return {
      dialogNumberShow: false, // 修改商品数量弹出框
      tipAffirmVisible: false, // 删除商品确认框
    }
  },
  methods: {
    // 打开数量输入框
    changeNumberHandle() {
      this.dialogNumberShow = true
      setTimeout(() => {
        window.addEventListener('click', this.listerHandle)
      }, 0)
    },
    // 监听，如果点击输入框外面则关闭输入框
    listerHandle(e) {
      let params = {
        userId: JSON.parse(localStorage.getItem('user')).id,
        count: this.data.count,
        spuId: this.data.skuId,
        addType: 1,
        skuId: this.data.skuId,
      }
      let a = e.path.some((item) => {
        if (item.className && item.className.indexOf('el-input-number') > -1) {
          return true
        } else {
          return false
        }
      })
      if (a) {
        return false
      } else {
        this.dialogNumberShow = false
        addShopCar(params)
          .then((res) => {
            if (res.code == '200') {
              this.$message.success(this.$trans('修改成功', '修改成功'))
              this.show = !this.show
              this.$emit('on')
            } else {
              this.$message.error(this.$trans('修改失败', '修改失败'))
            }
          })
          .catch(() => {
            this.$message.error(this.$trans('修改失败', '修改失败'))
          })
        window.removeEventListener('click', this.listerHandle)
      }
    },
    // 点击确定时删除商品
    ensureHandle(id) {
      deleteShopCar({
        skuIdList: [id],
        userId: JSON.parse(localStorage.getItem('user')).id,
      }).then((res) => {
        if (res.code == '200') {
          this.$message.success(this.$trans('删除成功', '删除成功'))
          this.$emit('on')
        }
      })
      this.tipAffirmVisible = false
    },
  },
  components: {
    SmallImg,
  },
}
</script>
<style scoped>
.shop-second__component {
  padding: 28px 0 35px;
  position: relative;
}
.item-img-wrap {
  width: 50px;
  height: 60px;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 30px;
  background: #f1f1f1;
}
.item-img-border {
  border: 1px solid #111111;
}
.item-img-border img {
  max-width: 30px;
  max-height: 40px;
  width: auto;
  height: auto;
}
.item__info {
  margin-left: 20px;
}
.object__message {
  margin-bottom: 10px;
}
.object__name {
  line-height: 1.3;
}
.object__author {
  font-size: 12px;
  color: #595959;
  margin-left: -5px;
  line-height: 1.3;
}
.object__price {
  font-size: 14px;
  line-height: 1.4;
}
.normal {
  font-style: normal;
}
.grey {
  color: rgb(153, 153, 153);
}
.shop-item__input {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}
.shop-item__input input {
  width: 100%;
  height: 100%;
  line-height: 35px;
  text-align: center;
  border: 1px solid rgb(200, 200, 200);
  outline: none;
}
.delete {
  cursor: pointer;
}
.specification {
  font-size: 12px;
  color: #999999;
}
</style>
<style>
.el-input-number--mini {
  width: 86px;
}
.delete-shop-popover .el-popover {
  right: 20px;
  top: 30px;
}
</style>
