<template>
  <div>
    <div class="title-shop-tip">
      <span>
        {{
          $trans(
            '本店部分商品为数字产品，一经购买，概不退换',
            '本店部分商品为数字产品，一经购买，概不退换'
          )
        }}</span
      >
    </div>
    <div class="title-shop-sum">
      <div class="shop-item__money">
        <span class="shop-item__int">
          {{ $trans('共计', '共计') }}：￥{{ sumMoney | product_int }}
        </span>
        <span class="shop-item__float">
          {{ sumMoney | product_float }}
        </span>
      </div>
      <div class="btn-fill" @click="summitOrderHandle">
        {{ $trans('立即购买', '立即购买') }}
      </div>
    </div>
  </div>
</template>
<script>
import { confirmOrder } from '@/util/js/api.js'
export default {
  name: '',
  props: {
    data: {
      typeof: Number | String,
    },
  },
  computed: {
    sumMoney() {
      return this.data.reduce((a, b) => {
        if (b.goodsStock == 2) {
          return a
        } else {
          return a + Number(b.price) * Number(b.count)
        }
      }, 0)
    },
  },
  methods: {
    // 提交订单
    summitOrderHandle() {
      const obj = this.getUserInfo.call(this, 'user')
      if (obj && obj['status'] != 3) {
        this.$message.warning(this.$trans('请先完成实名', '请先完成实名'))
        this.$emit('on')
        this.$router.history.push('/setting/authentication')
        return
      }
      if (this.$route.path != '/order/new') {
        let param = []
        let params = {}
        let goodsVoList = []
        this.data.forEach((item) => {
          let i = {
            count: item.count,
            skuId: item.skuId,
            price: item.price,
          }

          let obj = {
            userId: this.$store.state.userId,
            count: item.count,
            spuId: item.spuId,
            skuId: item.skuId,
            image: item.image,
            title: item.spuTitle,
            artistName: item.artistName,
            artistId: item.artistId,
            spu: item.spuId,
            price: item.price,
          }
          if (item.goodsStock == 1) {
            goodsVoList.push(i)
            param.push(obj)
          }
        })
        params.goodsVoList = goodsVoList
        params.userId = this.$store.state.userId
        confirmOrder(params)
          .then((res) => {
            if (res.code == '200') {
              this.$emit('on')
              this.$router.history.push({
                path: '/order/new',
                query: {
                  goodsDetailVoList: JSON.stringify(param),
                  orderToken: JSON.stringify(res.data.orderToken),
                  address: JSON.stringify(res.data.shangsfAddress),
                  isCartGoods: 1,
                  couponVoList: res.data.couponVoList
                    ? JSON.stringify(res.data.couponVoList)
                    : JSON.stringify([]),
                  shangsfSkuTemplateItemList: res.data.goodsVoList
                    ? JSON.stringify(res.data.goodsVoList)
                    : JSON.stringify([]),
                },
              })
            }
          })
          .catch(() => {
            this.$message.error(
              this.$trans(
                '确认订单信息错误,请稍后再试',
                '确认订单信息错误,请稍后再试'
              )
            )
          })
      } else {
        this.$emit('on')
        this.$message.warning(this.$trans('请先支付该订单', '请先支付该订单'))
      }
    },
  },
}
</script>
<style scoped>
.title-shop-tip {
  width: 100%;
  padding: 0 12px;
  font-size: 10px;
  color: rgb(200, 200, 200);
  line-height: 1;
}
.title-shop-tip div {
  margin-bottom: 17px;
}
.title-shop-sum {
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-shop-sum .shop-item__int {
  font-weight: bold;
  font-size: 20px;
}
.title-shop-sum .shop-item__float {
  transform: scale(0.8);
  margin-left: 0;
  margin-top: 2px;
}
.title-shop-sum .shop-item__money {
  margin-bottom: 15px;
}
.title-shop_tip {
  color: #666666;
  font-size: 10px;
  margin-bottom: 40px;
}
.title-shop-btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
</style>
